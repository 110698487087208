.displaySpinner {
  display: block;
}
.transactions-btn svg {
  font-size: 61px;
  display: block;
  text-align: center;
  margin: auto;
}
.transactions-btn .btn {
  font-weight: 600;
  margin-right: 27px;
  background-color: #fdfdfd;
  -moz-box-shadow: 0 0 7px #cecbcb;
  -webkit-box-shadow: 0 0 7px #cecbcb;
  box-shadow: 0 0 7px #cecbcb;
}
.transactions-btn .btn:nth-of-type(1) {
  color: #7dca7d;
}
.transactions-btn .btn:focus {
  -moz-box-shadow: inset 0 0 7px #cecbcb;
  -webkit-box-shadow: inset 0 0 7px #cecbcb;
  box-shadow: inset 0 0 7px #cecbcb;
}
.transactions-btn .btn:nth-of-type(2) {
  color: #f98d4c;
}
.transactions-btn .btn:nth-of-type(3) {
  color: #d6d225;
}

/* table .total-records {
  height: 463px;
} */
table .total-records {
  height: 75vh;
}

table th {
  padding: 7px;
}

body .notify-div .card-title {
  font-size: 17px;
  color: #16a7caf5;
}
.notify-div .card-body div {
  font-weight: 600;
}

.notify-div .card {
  box-shadow: 1px 1px 3px 1px gainsboro;
  -webkit-box-shadow: 1px 1px 3px 1px gainsboro;
  height: 187px;
  overflow: auto;
  background: linear-gradient(180deg, #ffefba, #fff);
}

.notify-div {
  height: 561px;
  overflow: auto;
}

.profileContentContainer {
  margin-top: 10px;
  transition: all 1s;
}
.profileContentContainer:hover {
  transform: scale(1.1);
  background-image: linear-gradient(to bottom right, #7774ff, #86ebff);
  color: white;
}
.constentValueStyle {
  /* color:"gray",fontSize:18,marginLeft:"5%"  */
  color: gray;
  font-size: 18px;
  margin-left: 5%;
}
.constentValueStyle:hover {
  /* color:"gray",fontSize:18,marginLeft:"5%"  */
  color: white;
  font-weight: bold;
}
.dynamicValue {
  /* color:"gray",fontSize:18,justifySelf:"flex-end",fontWeight:"bold",marginLeft:"50%" */
  color: gray;
  font-size: 18px;
  font-weight: bold;
  margin-left: 50%;
}

.dynamicValue:hover {
  /* color:"gray",fontSize:18,justifySelf:"flex-end",fontWeight:"bold",marginLeft:"50%" */
  color: white;
}

.transcation.row {
  margin-top: 50px;
}

svg.back-button {
  cursor: pointer;
}
